import ItemInfo from 'components/TransactionModal/itemInfo';
import pdfImage from 'assets/images/pdfIcon.png';
import xlsxIcon from 'assets/images/xlsxIcon.png';
import mp4Icon from 'assets/images/mp4IconL.png';
import mp3Icon from 'assets/images/mp3IconL.png';
import FileUpload from 'components/UI/FileUpload';
import { useEffect } from 'react';
import classNames from 'classnames';
import { transactionAcceptedFiles, transactionFileSupported } from 'utils/helper';

const getImageSource = (item) => {
  if (item?.name.includes('pdf')) return pdfImage;
  if (item?.name.includes('mp4')) return mp4Icon;
  if (item?.name.includes('mp3')) return mp3Icon;
  if (item?.name.includes('csv')) return xlsxIcon;
  if (item?.name.includes('xlsx')) return xlsxIcon;
  return item?.url;
};

/**

 * @param {array} receiptAssets: array of object consisting of name, url
 * @param {function} viewImgUrl: a callback function to open the receipt viewer
 * @returns
 */
const ReceiptList = ({
  receiptAssets,
  viewImgUrl,
  removeFile,
  addMore,
  onChange,
  loading,
  showLabel = true,
}) => {
  // useEffect(() => {
  //   if (receiptAssets?.length) viewImgUrl(receiptAssets[0], 'receipt');
  // }, [receiptAssets?.length]);

  return (
    <div>
      {showLabel && <ItemInfo title="Receipts or Attachments" />}
      <div
        className={classNames('receipt-asset-wrapper', {
          'padding-top-xxs': showLabel,
        })}
      >
        {receiptAssets?.map((item, index) => (
          <div
            className={
              item?.name?.includes('pdf') || item?.name?.includes('xlsx')
                ? 'receipt-asset pdf-small'
                : 'receipt-asset'
            }
            key={index}
            onClick={() => viewImgUrl(item, 'receipt')}
          >
            <img src={getImageSource(item)} alt={item?.name} />
          </div>
        ))}
        <div style={{ marginTop: '-12px' }}>
          <FileUpload
            name="file"
            onChange={onChange}
            multiple
            removeFile={removeFile}
            wrapperClass="new-receipt"
            isRounded={true}
            uploadingFile={loading}
            supportType={transactionFileSupported}
            acceptedFile={transactionAcceptedFiles}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceiptList;
