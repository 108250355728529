import { Breadcrumb, Input, Tooltip } from 'antd';
import {
  ChevronRight,
  Download02,
  EditPencilIcon,
  FilterIcon,
  GridIcon,
  HamburgerIcon,
  HomeLine,
  SaveDiskIcon,
  SaveIcon,
  SearchIcon,
} from 'assets/icons';
import cs from 'classnames';
import DateRangeFilter from 'components/DateRangeFilter';
import ExportDropdown from 'components/FilterModal/ExportDropdown';
import { activeFilterCount } from 'components/FilterModal/FilterHelper';
import FilterOnchange from 'components/FilterModal/FilterOnchange';
import CustomButton, { ActiveButton } from 'components/UI/CustomButton';
import CustomPills from 'components/UI/Pills';
import CustomPopover from 'components/UI/Popover';
import { subMonths } from 'date-fns';
import { useEffect, useState } from 'react';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getFromAndTo } from 'utils/utility';
import { ReactComponent as ADD } from '../../assets/icons/addBackground.svg';
import { ReactComponent as LinkArrow } from '../../assets/icons/linkArrow.svg';
import FilterModal from '../FilterModal';
import './styles.scss';
import { useMediaQuery } from 'react-responsive';

const TopBar = ({
  headerText,
  number,
  isRightBar,
  customAddButton = null,
  addIcon = false,
  breadcrumbs = [],
  addOnClick = null,
  largeTitle,
  colClass = '',
  filterData,
  inputPlaceholder,
  handleFilterSelect = () => null,
  handleFilterSelectOnChange = () => null,
  handleFilterApply = () => null,
  withDate = false,
  dateTitle,
  withOutSearch = false,
  addExport = false,
  addBeneficiary,
  searchVal,
  setSearchVal,
  filterOnchange,
  filterOnchangeData,
  clearFilters,
  csvData,
  exportPDF,
  exportLoading,
  handleExport,
  editLoading,
  handleEdit,
  saveLoading,
  handleSaveChanges,
  addButton,
  toggleButton,
  buttonName,
  showFilter = false,
  showBarSearch = false,
  addIconDropDown = false,
  showRetry = false,
  onRetryClick,
  actions: Actions,
  dropDownBtn,
  totalAmount,
  addActionButton,
  toggleActionButton,
  toggleView,
  activeView,
  handleToggleView,
  budgetStatus,
  setStatus,
  status,
  subText,
  filterProgramatically = false,
  csvPlatform = null,
  handleExportPlatform = null,
  hasEditButton = false,
  hasSaveChangesButton = false,
  showDateFilter = false,
  hasDateRangeFilter = false,
  csvFileName,
  selectedRange,
  setSelectedRange = () => null,
  flexWrap,
}) => {
  const dispatch = useDispatch();
  const { selectedDateRangeTopbar } = useSelector(({ overview }) => overview);

  const [isFilterPopover, setIsFilterPopover] = useState(false);
  const [isExportToggle, setIsExportToggle] = useState(false);
  const [isFilterOnchange, setIsFilterOnchange] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [filtersNumber, setFiltersNumber] = useState(activeFilterCount(filterData));

  const handleToggleFilterPopover = () => {
    setIsFilterPopover(!isFilterPopover);
    setIsFilterOnchange(false);
  };

  const handleExportToggle = () => {
    setIsExportToggle(!isExportToggle);
    if (!isExportToggle) handleExport();
  };

  const handleExportPlatformToggle = (platform) => {
    setIsExportToggle(!isExportToggle);
    if (!isExportToggle) handleExportPlatform(platform);
  };

  const handleSelectRange = (title, date) => {
    const { from, to } = getFromAndTo(date);
    setSelectedRange(title);
    handleFilterApply({ from, to });
  };

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const handleToggleOnchange = () => setIsFilterOnchange(!isFilterOnchange);

  const toggleIcons = [
    {
      name: 'list',
      icon: <HamburgerIcon />,
      toolTip: 'List view',
    },
    {
      name: 'grid',
      icon: <GridIcon width="15" height="15" />,
      toolTip: 'Grid view',
    },
  ];

  useEffect(() => {
    if (!!filterProgramatically) {
      const activeCount = filterData.filter((element) =>
        element.list.some(({ isSelected }) => isSelected),
      ).length;

      setFiltersNumber(activeCount);
    }
  }, [filterProgramatically, filterData]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div className="top-bar">
      <div className="px-0">
        <Row>
          <Col xs={12} className={cs({ 'pt-3': !colClass, [colClass]: colClass })}>
            <div className="px-3 fw-medium mb-2">
              {breadcrumbs.length > 0 && (
                <Breadcrumb separator={<ChevronRight stroke="#D7D3D0" />}>
                  <Breadcrumb.Item onClick={breadcrumbs[0].action} className={'cursor'}>
                    <span>
                      <HomeLine />
                    </span>
                  </Breadcrumb.Item>
                  {breadcrumbs.map((b, i) => (
                    <Breadcrumb.Item
                      key={i}
                      onClick={b.action}
                      className={breadcrumbs.length - 1 !== i ? 'cursor' : ''}
                    >
                      <span>{b.title}</span>
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              )}
            </div>
            <div
              className={cs(
                'w-100 d-flex align-items-center pb-md-2 pb-0 selected-budgets-wrapper',
                { 'd-xs-flex': !headerText && showBarSearch },
                { 'flex-wrap': flexWrap || headerText },
              )}
            >
              {budgetStatus && <CustomPills setStatus={setStatus} status={status} />}
              <div
                className={cs(
                  budgetStatus ? 'ms-auto' : hasDateRangeFilter ? '' : 'me-auto',
                  'my-2 my-lg-0',
                )}
              >
                <div className="d-flex">
                  {headerText && (
                    <p className={cs('headerText m-0', { ['large']: largeTitle })}>
                      {headerText}
                    </p>
                  )}
                  {number && <p className="textNumber m-0 ps-2">{number}</p>}
                </div>
                {subText && <div className="subText">{subText}</div>}
                {showBarSearch && (
                  <div
                    className={cs('search-input me-2', {
                      'w-100': flexWrap || headerText,
                    })}
                  >
                    <Input
                      className="input"
                      placeholder={inputPlaceholder}
                      prefix={<SearchIcon stroke="#A9A29D" className="searchIcon" />}
                      value={searchVal}
                      onChange={({ target: { value } }) => setSearchVal(value)}
                    />
                  </div>
                )}
              </div>

              {totalAmount ? (
                <div className="h-100 me-3 display-amount-text">{totalAmount}</div>
              ) : null}

              {addButton && (
                <div
                  className="d-flex gap-3 right-side position-relative"
                  style={{ alignSelf: 'start' }}
                >
                  {addActionButton && (
                    <>
                      {typeof addActionButton === 'string' ? (
                        <div
                          className="add-button add-custom cursor"
                          onClick={toggleActionButton}
                        >
                          {addActionButton}
                        </div>
                      ) : (
                        addActionButton
                      )}
                    </>
                  )}

                  {dropDownBtn ? (
                    <>{dropDownBtn}</>
                  ) : (
                    <>
                      {buttonName && (
                        <CustomButton className="add-button" onClick={toggleButton}>
                          {buttonName}
                        </CustomButton>
                      )}
                    </>
                  )}
                </div>
              )}

              {/* {!addButton && !buttonName && ( */}
              <div
                className={`${
                  hasDateRangeFilter ? 'flex-grow-1 justify-content-end' : ''
                } d-flex right-side align-items-center`}
              >
                {!isRightBar ? (
                  <>
                    {showRetry && (
                      <div className="retry-failed" onClick={onRetryClick}>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M-0.00012207 5.66667C-0.00012207 5.66667 1.37171 3.84548 2.48619 2.75883C3.60066 1.67218 5.14076 1 6.84198 1C10.2429 1 12.9999 3.68629 12.9999 7C12.9999 10.3137 10.2429 13 6.84198 13C4.0346 13 1.66601 11.1695 0.924771 8.66667M-0.00012207 5.66667V1.66667M-0.00012207 5.66667H4.10514"
                            stroke="#586068"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Retry Failed Transaction</span>
                      </div>
                    )}
                    {toggleView && (
                      <div className="d-flex toggle-view filter-button-wrapper">
                        {toggleIcons.map((icon) => {
                          return (
                            <Tooltip
                              placement="top"
                              title={icon.toolTip}
                              key={icon.name}
                              color={'#000'}
                            >
                              <span
                                className={activeView === icon.name ? 'active' : ''}
                                onClick={() => handleToggleView(icon.name)}
                              >
                                {icon.icon}
                              </span>
                            </Tooltip>
                          );
                        })}
                      </div>
                    )}

                    {showFilter && (
                      <CustomPopover
                        zIndex="2"
                        placement={isTabletOrMobile ? 'bottom' : 'bottom-end'}
                        id={isTabletOrMobile ? 'invoice-schedule' : ''}
                        content={
                          isFilterOnchange ? (
                            <FilterOnchange
                              filterData={filterOnchangeData}
                              inputPlaceholder={inputPlaceholder}
                              handleFilterSelect={handleFilterSelectOnChange}
                              withOutSearch={withOutSearch}
                              searchVal={searchVal}
                              setSearchVal={setSearchVal}
                              addBeneficiary={addBeneficiary}
                            />
                          ) : isFilterPopover ? (
                            <FilterModal
                              filterData={filterData}
                              inputPlaceholder={inputPlaceholder}
                              handleToggleFilterPopover={handleToggleFilterPopover}
                              handleFilterSelect={handleFilterSelect}
                              handleFilterApply={(query) => {
                                setFiltersNumber(Object.keys(query).length);
                                handleFilterApply(query);
                              }}
                              setSelectedRange={setSelectedRange}
                              withDate={withDate}
                              withOutSearch={withOutSearch}
                              clearFilters={clearFilters}
                              // setSelectedRange={setSelectedRange}
                            />
                          ) : null
                        }
                        showPopover={isFilterPopover || isFilterOnchange}
                        clickOutside={() => {
                          setIsFilterPopover(false);
                          setIsFilterOnchange(false);
                        }}
                        customClassName={`${hasDateRangeFilter ? 'me-auto' : ''}`}
                      >
                        <div className="d-flex gap-3 align-items-center">
                          <ActiveButton
                            variant="light"
                            position="bottom"
                            icon={<FilterIcon />}
                            className="d-flex w-100 align-items-center cursor filter-button-wrapper add-button unset-gap"
                            onClick={handleToggleFilterPopover}
                            text={
                              <Navbar.Text
                                className="ms-md-2 py-md-1 d-flex gap-1 align-items-center"
                                style={{ color: '#57534E' }}
                              >
                                <span className="d-md-block d-none m-0 p-0">Filters</span>
                                {!!filtersNumber && (
                                  <span
                                    style={{
                                      display: 'block',
                                      fontSize: 10,
                                      width: 16,
                                      height: 16,
                                      backgroundColor: '#000',
                                      borderRadius: '50%',
                                      color: '#fff',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {' '}
                                    {filtersNumber}
                                  </span>
                                )}
                              </Navbar.Text>
                            }
                          />

                          {filterOnchange && (
                            <div>
                              <ADD
                                onClick={() => {
                                  handleToggleOnchange();
                                  setIsFilterPopover(false);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </CustomPopover>
                    )}

                    {showDateFilter && (
                      <DateRangeFilter
                        handleSelectRange={handleSelectRange}
                        selectedRange={selectedRange}
                        numberOfMonths={2}
                        minDate={subMonths(new Date(), 22)} // Set the minimum date to 12 months ago
                        maxDate={new Date()}
                        lastYear={true}
                      />
                    )}

                    {addExport && (
                      <>
                        <CustomPopover
                          placement="bottom"
                          zIndex="1"
                          content={
                            <ExportDropdown
                              csvData={csvData}
                              csvPlatform={csvPlatform}
                              exportPDF={exportPDF}
                              handleExport={handleExportToggle}
                              exportLoading={exportLoading}
                              handleExportPlatform={handleExportPlatformToggle}
                              csvFileName={csvFileName}
                            />
                          }
                          showPopover={isExportToggle}
                          clickOutside={handleExportToggle}
                        >
                          <ActiveButton
                            variant="light"
                            position="bottom"
                            onClick={handleExportToggle}
                            icon={<Download02 stroke="#57534E" />}
                            text={
                              <Navbar.Text
                                className="ms-2 py-1 d-md-block d-none"
                                style={{ color: '#57534E' }}
                              >
                                Export
                              </Navbar.Text>
                            }
                            className="ml-4 d-flex align-items-center cursor filter-button-wrapper add-button unset-gap"
                          />
                        </CustomPopover>
                      </>
                    )}

                    {hasEditButton && (
                      <>
                        <div
                          className="ml-4 d-flex align-items-center cursor filter-button-wrapper"
                          onClick={handleEdit}
                        >
                          <EditPencilIcon width="14" height="14" />
                          <Navbar.Text className="ms-2 py-1 d-md-block d-none">
                            Edit
                          </Navbar.Text>
                        </div>
                      </>
                    )}

                    {hasSaveChangesButton && (
                      <>
                        <div
                          className="ml-4 d-flex align-items-center cursor filter-button-wrapper"
                          onClick={handleSaveChanges}
                        >
                          <SaveDiskIcon width="18" height="18" />
                          <Navbar.Text className="ms-2 py-1 d-md-block d-none">
                            Save Changes
                          </Navbar.Text>
                        </div>
                      </>
                    )}

                    {addIcon && (
                      <>
                        {customAddButton ? (
                          <div className="ms-3">{customAddButton}</div>
                        ) : (
                          <div className="ms-3 cursor">
                            {filterOnchange ? null : addIconDropDown ? (
                              <CustomPopover
                                zIndex="1"
                                showPopover={isPopoverOpen}
                                content={Actions(handleTogglePopover)}
                                clickOutside={handleExportToggle}
                                placement={isTabletOrMobile ? 'bottom' : 'bottom-end'}
                                id={isTabletOrMobile ? 'invoice-schedule' : ''}
                              >
                                <div
                                  className="ml-4 d-flex align-items-center cursor filter-button-wrapper"
                                  onClick={handleExportToggle}
                                >
                                  <LinkArrow />
                                  <Navbar.Text className="ms-2 py-1 d-md-block d-none">
                                    Export
                                  </Navbar.Text>
                                </div>
                              </CustomPopover>
                            ) : (
                              <ADD onClick={addOnClick} />
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  isRightBar
                )}
              </div>
              {/* )} */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default TopBar;
