import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Skeleton } from 'antd';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';

import ConfirmDialog from 'components/ConfirmDialog';
import { Modal } from 'react-bootstrap';
import { RESET_FLAGS_INVOICE } from 'redux/reducers/InvoiceReducer';
import { buildInvoiceTableData, getCurrency } from 'utils/helper';
import {
  deleteInvoice,
  deleteScheduledInvoice,
  fetchAllInvoices,
  fetchSingleInvoice,
  fetchSingleScheduledInvoice,
  markInvoiceAsPaid,
  shareInvoiceAction,
  updateInvoice,
} from '../../redux/actions/InvoiceAction';
import { invoiceColumns } from '../../utils/mockData';
import InvoiceDetailModal from './components/InvoiceDetailModal';

import { InvoiceStatusType } from 'components/FilterModal/FilterHelper';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import CurrencyFormat from 'react-currency-format';
import { numFormatter } from 'utils/utility';

import {
  DeleteOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  CopyLink,
  DownloadIcon,
  EditIcon,
  ReminderIcon,
  SquareCheckBox,
  UploadIcon,
} from 'assets/icons';
import classNames from 'classnames';
import DeleteDialog from 'components/DeleteDialog';
import { FullPageLoader } from 'components/UI/Loading';
import { toastSuccess } from 'components/UI/toast';
import copy from 'copy-text-to-clipboard';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import CollectionEmptyState from 'pages/Collections/CollectionEmptyState';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { getQueryParams, updateStatus } from 'utils/utility';
import DownloadPdfComponent from './components/DownloadPdfComponent';
import { syncAccountingEntity } from 'redux/actions/IntegrationsActions';
import AnalyticsCard from 'components/UI/AnalyticsCard';

import useSegmentVisibility from 'hooks/useSegmentVisibility';

const Invoices = ({ handleSelect, openCustomerModal }) => {
  const dispatch = useDispatch();
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(null);
  const [invoiceSelected, setInvoiceSelected] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [isSchedule, setIsSchedule] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [activeCode, setActiveCode] = useState('');
  const [activeInvoice, setActiveInvoice] = useState({});

  const pdfDownloadRef = useRef(null);

  const {
    getCompany: { data: companyData },
  } = useSelector(({ companies }) => companies);

  const history = useHistory();
  const location = useLocation();

  const [filterData, setFilterData] = useState([
    // { title: 'Amount', type: 'slider' },
    ...InvoiceStatusType,
  ]);

  const { permissions } = allPermissions();

  const canViewInvoice = hasPermission({
    permissions,
    scopes: ['invoice-*', 'invoice-view'],
  });

  const canDeleteInvoice = hasPermission({
    permissions,
    scopes: ['invoice-*', 'invoice-view', 'invoice-delete'],
  });

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({});

  const [search, setSearch] = useState(null);

  const debouncedValue = useDebounce(search, 300);

  const {
    fetchInvoices: { loading, success, data: { meta = {} } = {}, data = {} },
    customers: { data: customersData },

    deleteInvoice: { loading: isDeleting, success: deleteSuccess },
    deleteScheduledInvoice: {
      loading: isDeletingSchedule,
      success: deleteScheduleSuccess,
    },
    markInvoiceAsPaid: { loading: isMarking, success: markedAsPaid },
    updateInvoice: { loading: isUpdating, success: isUpdated },
    fetchInvoice: { loading: invoiceLoading, data: invoiceData },
    fetchSingleScheduledInvoice: { loading: isLoading, data: scheduledData },
  } = useSelector(({ invoices }) => invoices);

  const isInvoiceLoading = invoiceLoading || isLoading;
  const singleData = activeInvoice?.type === 'scheduled' ? scheduledData : invoiceData;
  const { page, total, hasMore, perPage, nextPage } = meta;

  useEffect(() => {
    // Load the image and convert it to a Base64 encoded string
    if (!!companyData?.logoUrl) {
      fetch(companyData?.logoUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64Data = reader?.result?.split(',')[1]; // Extract base64 data

            const img = new Image();
            img.src = `data:${blob.type};base64,${base64Data}`;
            img.onload = () => {
              const imageWidth = img.naturalWidth; // Get the image width
              const imageHeight = img.naturalHeight; // Get the image height

              // Calculate new dimensions while maintaining the aspect ratio
              let newWidth = 40;
              let newHeight = (40 * imageHeight) / imageWidth;

              // Check if the calculated height exceeds 40px
              if (newHeight > 40) {
                newWidth = (40 * imageWidth) / imageHeight;
                newHeight = 40;
              }
              setCompanyLogo({ base64Data, type: blob.type, newWidth, newHeight });
            };
          };
          reader.readAsDataURL(blob);
        })
        .catch((err) => {});
    }
  }, [companyData?.logoUrl]);

  const urlData = location?.state;

  useEffect(() => {
    if (urlData?.address) {
      openCustomerModal();
    }
  }, [urlData?.address]);

  const toggleInvoiceModal = () => {
    setIsInvoiceOpen(!isInvoiceOpen);

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('recurring')) {
      searchParams.delete('recurring');
      history.push({ search: searchParams.toString() });
    }
  };

  useEffect(() => {
    if (location?.search && canViewInvoice) {
      const code = getQueryParams(location?.search, 'customer');
      const status = getQueryParams(location?.search, 'status');
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams.has('recurring')) {
        searchParams.delete('recurring');
        history.push({ search: searchParams.toString() });
        dispatch(fetchAllInvoices());
      }

      if (code) dispatch(fetchAllInvoices({ customer: [code] }));
      if (status) {
        dispatch(fetchAllInvoices({ status }));
        setFilterData(updateStatus(filterData, 'Status', status));
      }
    }
  }, []);

  useEffect(() => {
    if (success && location?.search) isFiltered.current = true;
  }, [success]);

  const onHandleShareClick = (row) => {
    const code = row.details.code;
  };

  const fetchInvoices = () => {
    if (canViewInvoice) {
      dispatch(fetchAllInvoices());
    }
  };

  useEffect(() => {
    if (!data?.invoices?.length && !location?.search) fetchInvoices();
  }, []);

  useEffect(() => {
    return () => {
      if (filtered && canViewInvoice) dispatch(fetchAllInvoices());
    };
  }, [filtered]);

  useEffect(() => {
    if (customersData?.customers?.length) {
      const allCustomers = customersData?.customers.map((customer) => {
        return {
          value: customer?.code,
          label: customer?.name,
          isSelected: false,
        };
      });
      if (filterData.find((datum) => datum.title === 'Customer')) return;
      setFilterData([...filterData, { title: 'Customer', list: allCustomers }]);
    }
  }, [customersData?.customers, filterData]);

  const onHandleFilter = (query) => {
    const amount = filterData.filter(({ title }) => title === 'amount');

    const [min, max] = amount[0]?.value || [];
    const minAmount = min ? min * 100 : undefined;
    const maxAmount = max ? max * 100 : undefined;
    setFilteredQuery({ ...query, minAmount, maxAmount });

    dispatch(fetchAllInvoices({ ...query, minAmount, maxAmount }));
    isFiltered.current = !!Object.keys(query).length;
  };

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([...InvoiceStatusType]);
    // setFilterData([{ title: 'Amount', type: 'slider' }, ...InvoiceStatusType]);
    dispatch(fetchAllInvoices());
  };

  useEffect(() => {
    if (debouncedValue && canViewInvoice) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchAllInvoices({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewInvoice) {
      delete filteredQuery.search;
      dispatch(fetchAllInvoices({ ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const onHandleNextPage = (page) => {
    dispatch(fetchAllInvoices({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  const onHandlePreviousPage = (page) => {
    dispatch(fetchAllInvoices({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  const clearLocationState = () => {
    history.replace({ state: {} });
    fetchInvoices();
  };

  useEffect(() => {
    if (deleteSuccess || markedAsPaid || isUpdated || deleteScheduleSuccess) {
      setActiveCode('');
      setDeleteModal(null);
      dispatch(fetchAllInvoices({ ...filteredQuery }));
      dispatch({ type: RESET_FLAGS_INVOICE, blockType: 'deleteInvoice' });
    }
  }, [deleteSuccess, markedAsPaid, isUpdated, deleteScheduleSuccess]);

  const handleUpdateInvoice = (payload) => {
    return dispatch(updateInvoice(payload));
  };

  const sortDelete = (code, invoiceType = '') => {
    setDeleteModal(code);
    setIsSchedule(invoiceType === 'scheduled');
  };

  const downloadPDF = (event) => {
    event?.stopPropagation();
    event?.preventDefault();
    pdfDownloadRef?.current?.click();
  };

  const handlePdfDownload = (data) => {
    const { type = '', code, status } = data?.details ?? {};

    if (code === activeInvoice?.code || status === 'deleted') return;

    setActiveInvoice({ type, code });

    if (type === 'scheduled') {
      dispatch(fetchSingleScheduledInvoice({ code }));
    } else {
      dispatch(fetchSingleInvoice({ code: `${code}?includeTimeline=${true}` }));
    }
  };

  const handleSyncZoho = (invoice) => {
    dispatch(
      syncAccountingEntity({
        codes: [invoice.code],
        platform: 'zoho',
        element: 'invoices',
      }),
    );
  };

  const actionHandler = (event, type, value) => {
    const { code, hash, type: invoiceType = '' } = value?.details;
    event?.stopPropagation();
    event?.preventDefault();

    const editable = type === 'edit' || type === 'duplicate';
    if (editable) return handleEditables(value?.details, type);

    setIsPopoverOpen(true);
    setInvoiceSelected(value?.details);
    switch (type) {
      case 'share':
        return onHandleShareClick(value);
      case 'update':
        return handleUpdateInvoice({ code, status: value.status });
      case 'delete':
        return sortDelete(code, invoiceType);
      case 'mark-as-paid':
        return setActiveCode(code);
      case 'download':
        return downloadPDF(event, { code, type });
      case 'reminder':
        return sendReminder(code);
      case 'copy-link':
        return copyLink(hash);
      case 'print':
        return print(code);
      case 'syncZoho':
        return handleSyncZoho(value?.details);
    }
  };

  const copyLink = (hash) => {
    const windowLink = window.location.href;
    if (windowLink.includes('localhost') || windowLink.includes('dashboard-staging')) {
      copy(`https://dashboard-staging.bujeti.com/collections/invoices/details/${hash}`);
    } else {
      copy(`https://www.bujeti.com/invoices/${hash}`);
    }

    toastSuccess(`Invoice link copied succesfully`);
  };

  const sendReminder = (code) => {
    return dispatch(shareInvoiceAction({ method: 'post', code }));
  };

  const markAsPaid = () => {
    return dispatch(markInvoiceAsPaid(activeCode));
  };

  const rows = buildInvoiceTableData(data?.invoices || []);

  const handleRowClick = (row) => {
    setInvoiceSelected(row);
    setIsInvoiceOpen(true);

    row?.details?.type === 'scheduled' &&
      history.push(`${window?.location?.pathname}?recurring=true`);
  };

  const handleEditables = (details, type) => {
    history.push({
      pathname: '/receivables/invoices/create',
      state: { isRecurring: details?.type === 'scheduled' },
      search: `?${type === 'edit' ? 'isEdit' : 'duplicate'}=${details?.code}`,
    });
  };

  const Actions = ({ list }) => {
    handlePdfDownload(list);
    const isSchedule = list?.details?.type === 'scheduled';

    return (
      <div className="actions-dialog">
        <div className="actionLink mx-1" onClick={() => setIsPopoverOpen(true)}>
          <EyeOutlined
            style={{
              fontSize: '16px',
              marginLeft: '-8px',
              marginRight: '-5px',
              color: '#57534E',
            }}
          />{' '}
          View invoice
        </div>

        {!isSchedule && (
          <div
            className="actionLink mx-1"
            onClick={(event) => actionHandler(event, 'duplicate', list)}
          >
            <FileDoneOutlined
              style={{
                fontSize: '16px',
                marginLeft: '-8px',
                marginRight: '-5px',
                color: '#57534E',
              }}
            />{' '}
            Duplicate invoice
          </div>
        )}

        {!isSchedule &&
          !['draft', 'verifying', 'paid', 'declined'].includes(list?.details?.status) && (
            <div
              className="actionLink mx-1"
              onClick={(event) => actionHandler(event, 'mark-as-paid', list)}
            >
              <SquareCheckBox
                style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
              />{' '}
              Mark as Paid
            </div>
          )}

        {list?.details?.status === 'draft' && (
          <div
            className="actionLink mx-1"
            onClick={(event) => actionHandler(event, 'edit', { ...list })}
          >
            <EditIcon
              stroke="#79716B"
              width={16}
              height={16}
              style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
            />{' '}
            Edit draft
          </div>
        )}

        {!isSchedule &&
          !['draft', 'paid', 'verifying', 'declined'].includes(list?.details?.status) && (
            <div
              className="actionLink mx-1"
              onClick={(event) => actionHandler(event, 'reminder', list)}
            >
              <ReminderIcon
                height={16}
                width={16}
                style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
              />{' '}
              Send Reminder
            </div>
          )}

        {isSchedule ||
        ['draft', 'declined', 'verifying'].includes(list.details?.status) ? null : (
          <div
            className="actionLink mx-1"
            onClick={(event) => actionHandler(event, 'copy-link', list)}
          >
            <CopyLink
              style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
            />{' '}
            Copy invoice link
          </div>
        )}

        {!isSchedule && (
          <div
            className={classNames('actionLink mx-1', {
              ['disabled']: !companyLogo || isInvoiceLoading,
            })}
            onClick={(event) => actionHandler(event, 'download', list)}
          >
            <DownloadIcon
              style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
            />{' '}
            Download PDF{' '}
            {!companyLogo ||
              (isInvoiceLoading && (
                <div className="spinner-3 mx-auto" style={{ width: '18px' }}></div>
              ))}
          </div>
        )}

        <DownloadPdfComponent
          invoiceSelected={{ details: singleData }}
          companyData={companyData}
          pdfDownloadRef={pdfDownloadRef}
          companyLogo={{ ...companyLogo, logo: companyData?.logoUrl }}
        />

        {!list?.details?.invoiceData?.isZohoSynced && (
          <div
            className="actionLink mx-1"
            onClick={(event) => actionHandler(event, 'syncZoho', list)}
          >
            <UploadIcon
              className="mr-4"
              style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
            />{' '}
            Sync to Zoho Books
          </div>
        )}
        {canDeleteInvoice && (
          <div
            className="actionLink mx-1 text-danger"
            onClick={(event) => actionHandler(event, 'delete', list)}
          >
            <DeleteOutlined
              style={{ fontSize: '16px', marginLeft: '-8px', marginRight: '-3px' }}
            />{' '}
            Delete invoice
          </div>
        )}
      </div>
    );
  };

  const handleDelete = () => {
    const action = isSchedule
      ? deleteScheduledInvoice(deleteModal)
      : deleteInvoice(deleteModal);
    dispatch(action);
  };

  const visibleSegment = useSegmentVisibility(3);
  const show = !!data?.invoices?.length || (filtered && !data?.invoices?.length);

  if ((loading && !filtered) || (loading && location?.search))
    return (
      <section>
        {visibleSegment >= 1 && (
          <div className="fade-in">
            <InvoiceAnalytics loading />
          </div>
        )}

        {visibleSegment >= 2 && (
          <div className="d-flex mt-2 fade-in">
            <Skeleton.Button
              active
              shape="square"
              style={{ borderRadius: 8, height: '40px', width: '16rem' }}
            />

            <div className="ms-auto d-flex gap-2">
              <Skeleton.Button
                active
                shape="square"
                style={{ borderRadius: 8, height: '40px', width: '7rem' }}
              />
            </div>
          </div>
        )}

        {visibleSegment >= 3 && (
          <div className="mt-4 fade-in">
            <Table columns={invoiceColumns} data={rows} hasCheckBox={false} loading />
          </div>
        )}
      </section>
    );

  return (
    <>
      <PendingOnboardingNotice />
      <InvoiceAnalytics
        data={data}
        loading={loading}
        onFilter={onHandleFilter}
        filteredQuery={filteredQuery}
      />

      <TopBar
        colClass="pt-2"
        showBarSearch={show}
        searchVal={search}
        setSearchVal={setSearch}
        showFilter={show}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        filterData={filterData}
        handleFilterApply={onHandleFilter}
        clearFilters={clearFilters}
        inputPlaceholder="search"
        withDate
        dateTitle={'Invoice Date'}
      />

      {!data?.invoices?.length && (
        <div>
          {isFiltered.current || location?.search ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="You have no invoice for this filter"
                bodyText="There are no invoice for this filter, use the button below to show all your available invoices"
                withButton={true}
                buttonLabel={
                  <span className="d-flex justify-content-center">Show all Invoices</span>
                }
                onClickHandler={clearLocationState}
              />
            </div>
          ) : (
            <CollectionEmptyState
              onClickHandler={() => history.push('/receivables/invoices/create')}
              handleSelect={handleSelect}
            />
          )}
        </div>
      )}
      {!!data?.invoices?.length && (
        <>
          <Container className="px-0 invoice-table pb-3">
            <Row className="pt-4 pb-3">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={invoiceColumns}
                  pagination
                  data={rows}
                  onRowClick={handleRowClick}
                  hasCheckBox={false}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  hasMore={hasMore}
                  currentPage={page}
                  nextPage={() => onHandleNextPage(nextPage)}
                  previousPage={() => onHandlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                />
              </Col>
            </Row>
          </Container>
        </>
      )}

      <InvoiceDetailModal
        copyLink={copyLink}
        isOpen={isInvoiceOpen}
        handleDelete={sortDelete}
        companyLogo={companyLogo}
        actionHandler={actionHandler}
        handleClose={toggleInvoiceModal}
        canDeleteInvoice={canDeleteInvoice}
        code={invoiceSelected?.details?.code}
      />

      <Modal show={!!deleteModal} centered dialogClassName="custom-dialog">
        <DeleteDialog
          title="Delete invoice"
          subTitle="Are you sure you want delete this invoice?"
          onCancel={() => setDeleteModal(null)}
          onDelete={handleDelete}
          deleteText="Delete"
          isLoading={isDeleting || isDeletingSchedule}
        />
      </Modal>

      <Modal show={!!activeCode} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title="Mark as fully paid"
          subTitle="Are you sure you want to mark this invoice as paid?"
          isDeleteDialog={false}
          loading={isMarking}
          onConfirm={() => markAsPaid()}
          onCancel={() => setActiveCode('')}
        />
      </Modal>

      {isUpdating && <FullPageLoader />}
    </>
  );
};

const FormattedCurrency = ({ value, prefix, loading }) => {
  const formattedValue = numFormatter(value / 100);

  return (
    <>
      {loading ? (
        <Skeleton.Input
          className="mt-1"
          active
          size={20}
          style={{ borderRadius: 4, minWidth: '7rem' }}
        />
      ) : (
        <CurrencyFormat
          prefix={prefix}
          value={formattedValue}
          displayType="text"
          thousandSeparator={true}
          isNumericString
          renderText={(formattedValue) => {
            const [integerPart, decimalPart] = formattedValue.split('.');
            return (
              <span>
                {integerPart}
                {decimalPart && <span style={{ color: '#A9A29D' }}>.{decimalPart}</span>}
              </span>
            );
          }}
        />
      )}
    </>
  );
};

const InvoiceAnalytics = ({ data, loading, onFilter, filteredQuery }) => {
  const getSummaryValue = (key) => data?.summary?.[key]?.['NGN'] || 0;

  const analyticsData = [
    { caption: 'Total invoiced', key: 'totalInvoice' },
    { caption: 'Total received', key: 'totalReceived', query: 'paid' },
    { caption: 'Overdue invoices', key: 'totalOverdue', query: 'overdue' },
    { caption: 'Draft invoices', key: 'totalDraft', query: 'draft' },
  ];

  const handleCardClick = (query) => {
    if (!loading && query) {
      const currentQueries = filteredQuery?.status || [];

      let updatedQueries = isCardActive(query)
        ? currentQueries.filter((q) => q !== query)
        : [...currentQueries, query];

      const newQuery = {
        ...filteredQuery,
        ...(updatedQueries.length && { status: updatedQueries }),
      };

      if (!updatedQueries.length) {
        delete newQuery.status;
      }

      onFilter(newQuery);
    }
  };

  const isCardActive = (query) => {
    if (!query) return false;
    return filteredQuery?.status?.includes(query);
  };

  const renderAnalyticsCard = ({ caption, key, query }) => (
    <Col md={3} xs={6} className="mb-3" key={key} onClick={() => handleCardClick(query)}>
      <AnalyticsCard
        caption={caption}
        loading={loading}
        hasExtra={!!query}
        isActive={isCardActive(query)}
        value={
          <FormattedCurrency
            loading={loading}
            value={getSummaryValue(key)}
            prefix={getCurrency('NGN')}
          />
        }
      />
    </Col>
  );

  return (
    <section className="procurement-analytics-region">
      <Row>{analyticsData.map(renderAnalyticsCard)}</Row>
    </section>
  );
};

export default Invoices;
