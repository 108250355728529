import { ArrowLeftOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import FileUpload from 'components/UI/FileUpload';
import useTextCounter from 'hooks/useTextCounter';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getBalances, getBudgets } from 'redux/actions/BudgetsAction';
import { bulkVerifyBankAccounts } from 'redux/sagas/PaymentSaga';
import { MAXLENGTH, validateObjectData } from 'utils';
import { getAvailableBalance, groupSourceOptions, removeSpaces } from 'utils/helper';
import { read, utils } from 'xlsx';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg';
import BatchPayementTable from './BatchPayementTable';
import MultipleAccountUpload from './MultipleAccountUpload';
import './styles.scss';
import FixedSideModal from 'components/UI/fixed-side-modal';
import { toastError } from 'components/UI/toast';
import { getSimplifiedError } from 'utils/error';
// import { bulkVerifyBankAccounts } from 'redux/actions/PaymentAction';

export const rowInitialState = [
  {
    Recipient: '',
    'Account Number': '',
    'Bank Code': '',
    Source: null,
    currency: 'NGN',
    Amount: '',
    Category: '',
    Description: '',
  },
];

const MAX_LENGTH = MAXLENGTH?.singlePaymentDescription;

const BatchPayment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUploaded, setIsUploaded] = useState(false);
  const [batch, setBatch] = useState({ batchName: '', budget: '' });
  const [tableData, setTableData] = useState([]);
  const [isFile, setSetFile] = useState(false);
  const [externalUpload, setExternalUpload] = useState(false);
  const [removeFile, setRemoveFile] = useState(false);
  const [preview, setPreview] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const { state } = useLocation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [formRows, setFormRows] = useState(rowInitialState);

  const paymentTemplate =
    'https://bujeti-public.s3.eu-west-1.amazonaws.com/Bujeti_Batch_Transaction_Template.xlsx';

  const {
    getBudget: { data },
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const {
    fetchCategories: {
      data: { categories = [] } = {},
      loading: isCatLoading,
      success: isCatSuccess,
    },
  } = useSelector(({ categories }) => categories);

  const {
    bulkVerifyBankAccounts: { data: pay },
  } = useSelector(({ payments }) => payments);

  useEffect(() => {
    if (!balances?.budgets?.length) dispatch(getBalances());
  }, []);

  const readUploadFile = (files, fileName) => {
    if (files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);

        const fullDetails = json.map((object) => {
          return {
            ...object,
            Source: batch.budget,
          };
        });

        const allAccount = fullDetails.map((item) => ({
          accountNumber: String(removeSpaces(item['Account Number'])),
          bankCode: String(item['Bank Code']),
        }));

        // retry function
        // const retryPromise = (promiseFn, maxRetries, delayBetweenRetries) => {
        //   return new Promise((resolve, reject) => {
        //     let retries = 0;

        //     function attempt() {
        //       promiseFn()
        //         .then(resolve)
        //         .catch((error) => {
        //           retries++;
        //           if (retries <= maxRetries && error?.response?.status !== 400) {
        //             setTimeout(attempt, delayBetweenRetries);
        //           } else {
        //             reject(error);
        //           }
        //         });
        //     }

        //     attempt();
        //   });
        // };

        // //validate account by making request
        // const promises = allAccount.map(
        //   (accountInfo) => () =>
        //     retryPromise(() => verifyBankAccount(accountInfo), 3, 1000)
        //       .then((data) => data)
        //       .catch((error) => {
        //         console.error('Account validation failed');
        //       }),
        // );

        // //get validated response
        const getArrayOfObjects = async () => {
          setExternalUpload(true);
          return bulkVerifyBankAccounts({ accounts: allAccount })
            .then((data) => {
              return data.data;
            })
            .catch((error) => {
              toastError(getSimplifiedError(error));
              setRemoveFile(true);
            });
        };

        const verifyResponse = await getArrayOfObjects();

        const mergeArrays = async () => {
          setExternalUpload(false);

          const mergedArray = [];
          for (let index = 0; index < fullDetails?.length; index++) {
            for (
              let verifyIndex = 0;
              verifyIndex < verifyResponse?.length;
              verifyIndex++
            ) {
              if (index === verifyIndex) {
                mergedArray.push({
                  ...fullDetails[index],
                  Category: {
                    value: fullDetails[index]?.Category,
                    label: fullDetails[index]?.Category,
                  },
                  Recipient: verifyResponse[verifyIndex]?.verified
                    ? verifyResponse[verifyIndex]?.account_name
                    : '',
                  // error: verifyResponse[verifyIndex] ? false : true,
                });
              }
            }
          }
          return mergedArray;
        };
        const finalData = await mergeArrays();

        const updatedTableData = [...finalData, ...formRows];
        // const updatedTableData = [...formRows];
        const isValidRow = validateObjectData(formRows[0])?.isValid;
        if (verifyResponse)
          if ((formRows.length > 1 || isValidRow) && verifyResponse)
            setFormRows(updatedTableData);
          else {
            setFormRows(finalData);
            setSelectedRows([]);
            setSelectAll(false);
          }
        setIsUploaded(false);
      };
      reader.readAsArrayBuffer(files);
    } else {
      setIsUploaded(false);
    }

    setSetFile(!!fileName);
  };

  useEffect(() => {
    if (state?.value) setBatch({ ...batch, budget: state });
  }, [state]);

  useEffect(() => {
    if (!data?.budgets?.length) dispatch(getBudgets());
  }, []);

  const handleChange = ({ name, value }) => {
    setBatch({ ...batch, [name]: value });
  };

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, '35', false);
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const { text, charCount, handleCharChange } = useTextCounter(
    batch?.description,
    MAX_LENGTH,
    (value) => handleChange({ name: 'description', value }),
  );

  const categoriesList = useMemo(() => {
    return categories?.map((category) => ({
      value: category?.code,
      label: category?.name,
      name: category?.name,
    }));
  }, [isCatSuccess]);

  //
  function updateSecondWithFirst(firstArr, secondArr) {
    return firstArr.map((item) => {
      const index = secondArr.findIndex((_, index2) => index2 === item.id);
      if (index !== -1) {
        // Update the values from first array to second array
        delete item.id;
        const updatedItem = {
          ...secondArr[index],
          ...item,
          ...(batch?.budget && { Source: batch.budget }),
          ...(batch?.category?.label && {
            Category: { label: batch?.category?.label, value: batch?.category?.value },
          }),
          ...(batch?.description && { Description: batch?.description }),
        };
        secondArr[index] = updatedItem;
        return updatedItem;
      }
      return item;
    });
  }

  const handleMaxAssign = () => {
    updateSecondWithFirst(selectedRow, formRows);
    setSelectedRow([]);
    setSelectedRows([]);
    setSelectAll(false);
    setBatch({ ...batch, budget: '', category: '', description: '' });
  };

  const cancelAssign = () => {
    setSelectedRows([]);
    setSelectAll(false);
    setBatch({ ...batch, budget: '', category: '', description: '' });
  };

  return (
    <div
      className="d-flex w-100 gap-4 justify-content-between py-0 my-0"
      style={{ minHeight: '100vh' }}
    >
      <div
        className="my-0 batch_payment-container h-100"
        style={{
          width: `${!!selectedRow.length ? '70%' : '100%'}`,
          minHeight: '100%',
          paddingTop: '32px',
        }}
      >
        <div className="back-click pb-2" onClick={() => history.goBack(-1)}>
          <ArrowLeftOutlined />
          Cancel and go back
        </div>
        <h1 className="headerText m-0">Make a new payment</h1>
        <h6 className="subtext">Initiate a new batch payments.</h6>
        {!isUploaded && (
          <div className="mt-4 overview-holder" style={{ height: `calc(100vh - 12rem)` }}>
            <Row className="w-100">
              <Col xs={12} lg={4}>
                <CustomInput
                  label="Batch name"
                  placeholder="Enter batch Name"
                  type="text"
                  className="w-100"
                  name="batchName"
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                  value={batch.batchName}
                  maxLength={60}
                />
              </Col>
            </Row>
            <Row className="w-100 flex align-items-center">
              <Col xs={12} lg={8}>
                <FileUpload
                  supportType=".CSV or .XLSX file"
                  cloud={false}
                  acceptedFile={{ 'text/html': ['.xlsx', '.csv'] }}
                  onChange={({ file, fileName }) => readUploadFile(file, fileName)}
                  // uploadingFile={!batch.budget}
                  // checkIsDisabled={handleCheckIsDisabled}
                  externalUpload={externalUpload}
                  removeFile={removeFile}
                  setRemoveFile={setRemoveFile}
                />
              </Col>
              {!isFile && (
                <Col xs={12} lg={4} className="mt-3">
                  <div className="download-item">
                    <a
                      href={paymentTemplate}
                      className="text-decoration-none text-reset"
                      download
                      rel="noreferrer"
                    >
                      <div className="d-flex align-items-center flex-column p-2">
                        <DownloadIcon className="mb-2" />
                        <div className="text-center text-download">
                          <h5 className="m-0 ">Download template</h5>
                          <p className="m-0 ">
                            Don’t have a batch file? Don’t worry we got you.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </Col>
              )}
            </Row>

            {/* Add multiple accounts */}
            {!!formRows.length && (
              <div>
                <MultipleAccountUpload
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  loadOptions={loadOptions}
                  loadingBalances={loadingBalances}
                  setTableData={setTableData}
                  tableData={tableData}
                  budgetList={budgetList}
                  batchName={batch.batchName}
                  isUploaded={isUploaded}
                  setIsUploaded={setIsUploaded}
                  formRows={formRows}
                  setFormRows={setFormRows}
                  getSelected={setSelectedRow}
                  setPreview={setPreview}
                />
              </div>
            )}
          </div>
        )}

        <div className="w-100 ">
          <BatchPayementTable
            tableData={tableData}
            setTableData={setTableData}
            isFile={isFile && !!tableData.length}
            setRemoveFile={setRemoveFile}
            setBatch={setBatch}
            isUploaded={isUploaded}
            setIsUploaded={setIsUploaded}
            formRows={formRows}
            setFormRows={setFormRows}
            preview={preview}
            setPreview={setPreview}
            isSubmit={isSubmit}
            setIsSubmit={setIsSubmit}
          />
        </div>
      </div>

      {!!selectedRow.length && (
        <FixedSideModal open={!!selectedRow.length}>
          <div className="selected-side-modal ">
            <h5 className="m-0">Transaction selected ({selectedRow.length})</h5>

            <Col className="mt-1">
              <CustomSelectRadio
                label="Source of funds"
                name="budgets"
                placeholder="Select a source"
                onChange={(val) => handleChange({ name: 'budget', value: val })}
                value={batch.budget}
                isDisabled={loadingBalances}
                isLoading={loadingBalances}
                loadOptions={loadOptions}
                isClearable
              />
            </Col>

            <Col className="mt-2">
              <CustomSelect
                label="Category"
                options={categoriesList}
                isLoading={isCatLoading}
                isDisabled={isCatLoading}
                placeholder="Select a category"
                value={batch?.category}
                onChange={(val) => handleChange({ name: 'category', value: val })}
                isClearable
              />
            </Col>

            <Col className="mt-2">
              <CustomTextarea
                rowSize={3}
                label="Description"
                name="description"
                onChange={handleCharChange}
                value={batch?.description}
                placeholder="Enter description"
                maxLength={MAX_LENGTH}
                showCounter={true}
                charCount={charCount}
              />
            </Col>

            <div className="modal-footer w-100">
              <CustomButton
                onClick={cancelAssign}
                fullWidth
                className="custom-button ghost-button"
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={handleMaxAssign}
                fullWidth
                className="custom-button primary-button"
              >
                Apply changes
              </CustomButton>
            </div>
          </div>
        </FixedSideModal>
      )}
    </div>
  );
};

export default BatchPayment;
